import { Model } from '@vuex-orm/core';

export default class Objekt extends Model {
  static entity = 'benutzer';
  static fields() {
    return {
      id: this.number(0).nullable(),
      msid: this.string('').nullable(),
      email: this.string('').nullable(),
      passwort: this.string('').nullable(),
      anrede: this.string('').nullable(),
      vorname: this.string('').nullable(),
      nachname: this.string('').nullable(),
      firma: this.string('').nullable(),
      plz: this.string('').nullable(),
      ort: this.string('').nullable(),
      mobil: this.string('').nullable(),
      telefon: this.string('').nullable(),
      fax: this.string('').nullable(),
      status: this.number(0).nullable(),
      idhash: this.string('').nullable(),
      erstelltam: this.attr(undefined).nullable(),
      newsletter: this.number(0).nullable(),
      acktimestamp: this.attr(undefined).nullable(),
      profil: this.number(0).nullable(),
      adresse1: this.string('').nullable(),
      adresse2: this.string('').nullable(),
      modtimestamp: this.attr(undefined).nullable(),
      lastlogin: this.attr(undefined).nullable(),
      favoriten: this.attr([]).nullable(),
      suchparameter: this.string('').nullable(),
      hilfe: this.string('').nullable(),
      objektinhaber: this.number(0).nullable(),
      interessen: this.string('').nullable(),
      userlevel: this.number(0).nullable(),
      maillevel: this.number(0).nullable(),
      nologging: this.number(0).nullable(),
      agbversion: this.number(0).nullable(),
      agbackdate: this.attr(undefined).nullable()
    };
  }
}
